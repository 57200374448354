import React from 'react'
import * as styles from '../css/title.module.css'
import classNames from 'classNames'

export const Title = ({ children, className, style, Tag = 'h1' }) => {
  const Highlight = ({
    children: highlightChildren,
    lineClassName,
    style: wrapperStyle,
    className: wrapperClassName,
  }) => {
    return (
      <span
        style={wrapperStyle}
        className={classNames(styles.wrapper, wrapperClassName)}
      >
        {highlightChildren}
        <span className={classNames(styles.gradientLine, lineClassName)}></span>
      </span>
    )
  }

  return (
    <Tag className={classNames(styles.title, className)} style={style}>
      {children(Highlight)}
    </Tag>
  )
}
