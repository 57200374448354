import React from 'react'
export const PlusIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    {...props}
  >
    <path
      id="Union_3"
      data-name="Union 3"
      d="M6,13.5V9H1.5a1.5,1.5,0,1,1,0-3H6V1.5a1.5,1.5,0,1,1,3,0V6h4.5a1.5,1.5,0,1,1,0,3H9v4.5a1.5,1.5,0,1,1-3,0Z"
      fill="#fff"
    />
  </svg>
)
