import React from 'react'
import { Title } from '@components'
import * as styles from '@css/how_it_works.module.css'
import { useContent } from '@contexts'

const Step = ({ children, n }) => (
  <li className={styles.step}>
    <span className={styles.stepNumber}>{n}.</span>
    {children}
  </li>
)

export const HowItWorks = () => {
  const content = useContent()
  const howItWorks = content?.howItWorks || {}

  return (
    <section>
      <Title>
        {Highlight => (
          <>
            {howItWorks.title?.text}
            {howItWorks.title?.highlighted ? (
              <Highlight>{howItWorks.title?.highlighted}</Highlight>
            ) : null}
          </>
        )}
      </Title>
      <ol className={styles.list}>
        {howItWorks.steps.map(step => (
          <Step n={step.order}>{step.text}</Step>
        ))}
      </ol>
      <h2 className="text-center">{howItWorks.footer?.text}</h2>
    </section>
  )
}
