import React from 'react'
import * as styles from '@css/pricing_table.module.css'
import PropTypes from 'prop-types'
import { GradientPolygon } from '@icons'
import classNames from 'classnames'

const Plan = ({
  title,
  subTitle,
  price,
  period,
  points,
  style,
  mostPopular,
}) => {
  return (
    <div
      className={classNames({
        [styles.plan]: true,
        [styles.hasMostPopular]: mostPopular,
      })}
      style={style}
    >
      {mostPopular && <h3 className={styles.mostPopular}>Most Popular</h3>}
      <h1 className={styles.title}>{title}</h1>
      <h3 className={styles.subTitle}>{subTitle}</h3>
      <div className={styles.priceWrapper}>
        <span className={styles.price}>{price}</span>
        <span className={styles.period}>{period}</span>
      </div>
      <ul className={styles.pointsList}>
        {points.map(point => (
          <li key={point} className={styles.point}>
            <GradientPolygon className={styles.icon} />
            {point}
          </li>
        ))}
      </ul>
    </div>
  )
}

const PricingTable = ({ children }) => {
  return <div className={styles.pricingTable}>{children}</div>
}

Plan.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  price: PropTypes.string,
  period: PropTypes.string,
  points: PropTypes.arrayOf(PropTypes.string),
  mostPopular: PropTypes.bool,
}

export { Plan, PricingTable }
