import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { JoinWaitListModal } from '@components'
import classNames from 'classnames'
import { subscribe } from 'pubsub-js'
import '../css/layout.css'
import '../css/shared.css'

export const Layout = ({ children }) => {
  const [opened, setOpened] = useState(false)
  const [joined, setJoined] = useState(false)

  subscribe('joined-the-waitlist', () => {
    setJoined(true)
  })

  return (
    <>
      <div className="site-container">
        <main>{children}</main>
        {!opened && (
          <button
            className={classNames({
              'glowing-button': true,
              'floating-join-button': true,
              success: joined,
            })}
            onClick={() => setOpened(true)}
          >
            {joined
              ? 'Awesome 🙂 we are currently getting many requests, we will reach out to you during the next days'
              : 'Please give me the leads'}
          </button>
        )}
        <JoinWaitListModal opened={opened} setOpened={setOpened} />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
