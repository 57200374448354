import React from 'react'
import * as styles from '@css/pricing.module.css'
import { Title, PricingTable, Plan } from '@components'
import { useContent } from '@contexts'

export const Pricing = () => {
  const content = useContent()
  const pricing = content?.pricing || {}

  return (
    <section>
      <PricingTable>
        <Plan
          title="Free Plan"
          subTitle="For you to try out our service"
          price="$0.00"
          points={['Access 20 Leads per Week (Limited Data Points)']}
        />
        <Plan
          title="Starter Plan"
          subTitle="For small companies and startups"
          price="$79"
          period="/month"
          points={[
            '200 Companies Every Week (All Data Points)',
            'If paid annually (Direct Access to 1200 Leads)',
          ]}
          style={{
            background:
              'linear-gradient(230deg, #ff00d5 0%, #6033f8 50%, #23f6ef 100%)',
          }}
        />
        <Plan
          title="Pro Plan"
          subTitle="Personalized email done for you"
          price="$199"
          period="/month"
          points={[
            'Same as starter plan but we also personalize the email (for each lead we create a personalized email line)',
          ]}
          style={{
            background:
              'linear-gradient(325deg, #ff00d5 0%, #6033f8 50%, #23f6ef 100%)',
          }}
          mostPopular
        />
        <Plan
          title="Done for you"
          subTitle="We got everything covered for you"
          price="$499"
          period="/month"
          points={['Same as other plans but we do the email marketing for you']}
          style={{
            background:
              'linear-gradient(120deg, #ff00d5 0%, #6033f8 50%, #23f6ef 100%)',
          }}
        />
      </PricingTable>
    </section>
  )
}
