import React from 'react'
import { Title, Ellipse } from '@components'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'
import * as styles from '@css/faq.module.css'
import { MinusIcon, PlusIcon } from '@icons'
import { useContent } from '@contexts'

export const Faq = () => {
  const FAQAccordionButton = ({ opened }) => {
    return (
      <div className={styles.accordionButton}>
        {opened ? (
          <MinusIcon className={styles.accordionItemIcon} />
        ) : (
          <PlusIcon className={styles.accordionItemIcon} />
        )}
      </div>
    )
  }

  const FAQAccordion = ({ title, children, uuid }) => (
    <AccordionItem className={styles.accordionItem} uuid={uuid}>
      <AccordionItemHeading>
        <AccordionItemButton className={styles.accordionItemButton}>
          <AccordionItemState>
            {({ expanded }) => <FAQAccordionButton opened={expanded} />}
          </AccordionItemState>
          <span className={styles.accordionText}>{title}</span>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className={styles.accordionPanel}>
        {children}
      </AccordionItemPanel>
    </AccordionItem>
  )

  const content = useContent()
  const faq = content?.faq || {}

  return (
    <section>
      <Ellipse width="400px" height="400px" top="-150px" left="-60px" />
      <Title>
        {Highlight => (
          <>
            {faq.title?.text}
            {faq.title?.highlighted ? (
              <Highlight>{howItWorks.title?.highlighted}</Highlight>
            ) : null}
            {}
          </>
        )}
      </Title>
      <Accordion
        className={styles.accordion}
        allowMultipleExpanded={false}
        preExpanded={['a']}
      >
        {faq.items?.map((question, index) => (
          <FAQAccordion
            uuid={index === 0 ? 'a' : index}
            title={question.question}
          >
            {question.answer}
          </FAQAccordion>
        ))}
      </Accordion>
    </section>
  )
}
