import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Title, GifEmoji, TextSlider } from '@components'
import * as styles from '@css/work.module.css'
import { useContent } from '@contexts'

export const Work = () => {
  const content = useContent()
  const work = content?.work || {}

  return (
    <section>
      <Title>
        {Highlight => (
          <>
            {work.title?.text}
            <Highlight>{work.title?.highlighted}</Highlight>
            <br />
            {work.title?.text2}
          </>
        )}
      </Title>
      <p className="text-center">{work.subTitle?.text}</p>
      <TextSlider items={work.categories} />
      <div className={styles.arrowUp}>&uarr;</div>
      <h2 className="text-center">{work.categoriesTitle?.text}</h2>
    </section>
  )
}
