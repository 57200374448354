import React from 'react'
import { GifEmoji, Ellipse } from '@components'
import * as styles from '@css/home_section.module.css'
import { useContent } from '@contexts'
import { Title } from 'marketing-sites-components'

export const HomeSection = () => {
  const content = useContent()
  const home = content?.home || {}

  return (
    <section className={styles.section}>
      <Ellipse />
      <Title>
        {Highlight => (
          <>
            {home.title?.text}
            <Highlight>{home.title?.highlighted}</Highlight>
            {home.title?.text2}
          </>
        )}
      </Title>
      <h2 className={styles.slogan}>
        {home.subText?.text1} <GifEmoji emoji="love_letter" /> <br />
        <strong>{home.subText?.text2}</strong>
      </h2>
    </section>
  )
}
