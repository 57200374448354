// extracted by mini-css-extract-plugin
export var hasMostPopular = "pricing_table-module--hasMostPopular--itIaQ";
export var icon = "pricing_table-module--icon--uG7Jl";
export var mostPopular = "pricing_table-module--mostPopular--lyFUm";
export var period = "pricing_table-module--period--Hfy-e";
export var plan = "pricing_table-module--plan--aAHvO";
export var point = "pricing_table-module--point--fwoI2";
export var price = "pricing_table-module--price--V2uFU";
export var priceWrapper = "pricing_table-module--priceWrapper--NPY9r";
export var pricingTable = "pricing_table-module--pricingTable--znblp";
export var subTitle = "pricing_table-module--subTitle--+vfM4";
export var title = "pricing_table-module--title--3q1g6";