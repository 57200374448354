import React from 'react'
import { useGetImage } from '@contexts'
import * as styles from '@css/gif_emoji.module.css'
import classNames from 'classnames'

export const GifEmoji = props => {
  const image = useGetImage(props.emoji)
  return (
    <img
      className={classNames(styles.img, props.className)}
      src={image}
      alt={props.emoji}
      width={28}
      {...props}
    />
  )
}
