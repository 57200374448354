// extracted by mini-css-extract-plugin
export var content = "join_wait_list_modal-module--content--KMHhG";
export var emailInput = "join_wait_list_modal-module--emailInput---qPPK";
export var errorMessage = "join_wait_list_modal-module--errorMessage--D7kwU";
export var joinButton = "join_wait_list_modal-module--joinButton--v1ac1";
export var opened = "join_wait_list_modal-module--opened--JDUGk";
export var overlay = "join_wait_list_modal-module--overlay--bLgrq";
export var phoneNumber = "join_wait_list_modal-module--phoneNumber--NEyd1";
export var phoneSubText = "join_wait_list_modal-module--phoneSubText--Ao5Fs";
export var showEmail = "join_wait_list_modal-module--showEmail--UEM4z";
export var showJoinButton = "join_wait_list_modal-module--showJoinButton--O0YXp";
export var wrapper = "join_wait_list_modal-module--wrapper--iXvu8";