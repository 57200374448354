import React from 'react'
export const MinusIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="3"
    viewBox="0 0 15 3"
    {...props}
  >
    <rect
      id="Rectangle_106"
      data-name="Rectangle 106"
      width="15"
      height="3"
      rx="1.5"
      fill="#fff"
    />
  </svg>
)
