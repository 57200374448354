import * as React from 'react'

import { Layout, Seo } from '@components'

import {
  Faq,
  Work,
  HomeSection,
  WhyAcquireLeads,
  Solutions,
  Pricing,
  HowItWorks,
  SpotsCounter,
} from '@components/Sections'

import { SharedImagesProvider, ContentProvider } from '@contexts'

const IndexPage = ({ pageContext }) => {
  return (
    <Layout>
      <SharedImagesProvider>
        <ContentProvider content={pageContext.content}>
          <HomeSection />
          <WhyAcquireLeads />
          <Solutions />
          <HowItWorks />
          <Work />
          <Pricing />
          <SpotsCounter />
          <Faq />
        </ContentProvider>
      </SharedImagesProvider>
    </Layout>
  )
}

export const Head = () => (
  <Seo title="AcquireLeads - Fill your pipeline with warmed up leads" />
)

export default IndexPage
