import React, { useEffect, useState } from 'react'
import * as styles from '@css/join_wait_list_modal.module.css'
import classNames from 'classnames'
import { publish } from 'pubsub-js'
import { useForm } from 'react-hook-form'
import MobileDetect from 'mobile-detect'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import 'react-phone-number-input/style.css'

export const JoinWaitListModal = ({ opened, setOpened }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()
  const [country, setCountry] = useState('US')

  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_API_BASE_URL}/get-ip-country`)
  //     .then(res => res.json())
  //     .then(json => {
  //       setCountry(json.country)
  //     })
  // }, [])

  const addUserToWaitList = async ({ email, phone }) => {
    const md = new MobileDetect(window.navigator.userAgent)
    fetch(`${process.env.REACT_APP_API_BASE_URL}/add-to-waitlist`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        phoneNumber: phone,
        OS: md.os(),
        country,
        listId: 'acquireleads',
      }),
    })
    setOpened(false)
    publish('joined-the-waitlist')
  }

  return (
    <div className={`${styles.wrapper} ${opened ? styles.opened : ''}`}>
      <div className={styles.content}>
        <input
          className={classNames({
            [`${styles.emailInput}`]: true,
            [`${styles.showEmail}`]: opened,
          })}
          type="email"
          placeholder="Your email address"
          {...register('email', {
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Pease enter a valid email address!',
            },
            required: 'Please enter your email address!',
          })}
        />
        <label className={styles.errorMessage}>{errors.email?.message}</label>
        <PhoneInputWithCountry
          name="phone"
          placeholder="Enter phone number"
          control={control}
          className={classNames(styles.phoneNumber, styles.showEmail)}
          defaultCountry={country}
        />
        <p className={styles.phoneSubText}>
          Don't worry we won't call you. We will send the next steps via email
          or text. We feel calling without a planned meeting is spammy.
        </p>
        <button
          className={classNames({
            'glowing-button': true,
            [`${styles.joinButton}`]: true,
            [`${styles.showJoinButton}`]: opened,
          })}
          onClick={handleSubmit(addUserToWaitList)}
        >
          APPLY NOW
        </button>
      </div>
      <div
        onClick={() => setOpened(false)}
        onKeyDown={event => {
          // if the key is ESC, close the modal
          if (event.key === 'Escape') {
            setOpened(false)
          }
        }}
        className={styles.overlay}
      ></div>
    </div>
  )
}
