import React from 'react'
import * as styles from '../css/title.module.css'
import classNames from 'classNames'
import PropTypes from 'prop-types'

/**
 * @prop tag - The HTML tag to use for the title.
 * @prop className - The className to use for the title.
 */
const Title = ({ children, className, style, Tag }) => {
  const Highlight = ({
    children: highlightChildren,
    lineClassName,
    style: wrapperStyle,
    className: wrapperClassName,
  }) => {
    return (
      <span
        style={wrapperStyle}
        className={classNames(styles.wrapper, wrapperClassName)}
      >
        {highlightChildren}
        <span className={classNames(styles.gradientLine, lineClassName)}></span>
      </span>
    )
  }

  return (
    <Tag className={classNames(styles.title, className)} style={style}>
      {children(Highlight)}
    </Tag>
  )
}

Title.prototype = {
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  Tag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
}

Title.defaultProps = {
  Tag: 'h1',
}

export {
  Title
}