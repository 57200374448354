import React from 'react'
import { Title, Solution, Ellipse } from '@components'
import * as styles from '@css/solutions.module.css'
import { useContent } from '@contexts'

export const Solutions = ({ children }) => {
  const content = useContent()
  const solutions = content?.solutions || {}
  const recap = content?.recap || {}

  return (
    <section>
      <Ellipse className={styles.ellipse} />
      <Title>
        {Highlight => (
          <>
            {solutions.title?.text}
            <Highlight>{solutions.title?.highlighted}</Highlight>
            <br />
            {solutions.title?.text2}
          </>
        )}
      </Title>
      <h3 className="sub-title">{solutions?.subTitle?.text}</h3>
      <div className={styles.solutionsWrapper}>
        {solutions.items?.map(item => (
          <Solution problem={item.problem}>{item.text}</Solution>
        ))}
      </div>
      {/* <div className={styles.recap}>
        <Ellipse className={styles.recapEllipse} />
        <Title>
          {Highlight => (
            <>
              {recap.title?.text}
              <Highlight>{recap.title?.highlighted}</Highlight>
              <br />
              {recap.title?.text2}
            </>
          )}
        </Title>
        <h2 className={styles.recapText}>
          {recap.body?.text} <br />
          <strong>{recap.body?.boldText}</strong>
        </h2>
      </div> */}
    </section>
  )
}
