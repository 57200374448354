import React, { useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

const Video = ({ videoSrcURL, videoTitle, ...props }) => {
  const { allFile } = useStaticQuery(graphql`
    {
      allFile {
        totalCount
        nodes {
          name
          absolutePath
          relativePath
          publicURL
        }
      }
    }
  `)

  const filePublicUrl = useMemo(() => {
    return {
      mp4: allFile.nodes.find(
        (file) => file.relativePath === videoSrcURL + '.mp4'
      )?.publicURL,
      webm: allFile.nodes.find(
        (file) => file.relativePath === videoSrcURL + '.webm'
      )?.publicURL,
    }
  }, [allFile, videoSrcURL])

  return (
    <video
      allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
      frameBorder='0'
      webkitAllowFullscreen='true'
      mozAllowFullscreen='true'
      allowFullScreen
      playsInline
      autoPlay
      muted
      loop
      {...props}
    >
      <source src={filePublicUrl.webm} type='video/webm' />
      <source src={filePublicUrl.mp4} type='video/mp4' />
    </video>
  )
}

Video.prototype = {
  videoSrcURL: PropTypes.string.isRequired,
  videoTitle: PropTypes.string,
}

export { Video }
