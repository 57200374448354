import React, { useState } from 'react'
import * as styles from '@css/spots_counter.module.css'

export const SpotsCounter = () => {
  const MAX_SPOTS = 100

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const counter = randomIntFromInterval(95, MAX_SPOTS - 2)
  const remainingSpots = MAX_SPOTS - counter

  return (
    <section className={styles.wrapper}>
      <h2 className={styles.occupied}>
        <span className={styles.highlight}>{counter}</span> out of{' '}
        <span className={styles.highlight}>{MAX_SPOTS}</span> spots occupied
      </h2>
      <h2 className={styles.freeSpots}>
        Only <span className={styles.remainingSpots}>{remainingSpots}</span>{' '}
        left
      </h2>
      Why? We make sure to have a maximum of{' '}
      <span className={styles.highlight}>{MAX_SPOTS}</span> clients so that
      everyone can get the best results.
    </section>
  )
}
