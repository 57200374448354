import React, { useEffect, useMemo, useState } from 'react'
import * as styles from '@css/text_slider.module.css'
import classNames from 'classnames'

export const TextSlider = ({ items }) => {
  const [cursor, setCursor] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCursor(cursor => {
        if (cursor + 1 === items.length) {
          return 0
        }
        return cursor + 1
      })
    }, 2000)
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const renderItems = useMemo(() => {
    return items.map((item, index) => (
      <li
        key={item}
        className={classNames({
          [styles.item]: true,
          [styles.itemIsActive]: cursor === index,
        })}
      >
        {item}
      </li>
    ))
  }, [items, cursor])

  return <div className={styles.wrapper}>{renderItems}</div>
}
