import React from 'react'
import { Title } from 'marketing-sites-components'
import { TextIcon } from '../TextIcon'
import * as styles from '@css/why_acquire_leads.module.css'
import classNames from 'classnames'
import { useContent } from '@contexts'

export const WhyAcquireLeads = () => {
  const content = useContent()
  const why = content?.why || {}

  return (
    <section className={styles.section}>
      <Title className={styles.title}>
        {Highlight => (
          <>
            {why.title?.text}
            <Highlight>{why.title?.highlighted}</Highlight>
            <br />
            {why.title?.text2}
          </>
        )}
      </Title>
      <div className={classNames('flex-layout', styles.useCasesWrapper)}>
        {why.items?.map(item => (
          <TextIcon key={item.title} title={item.title}>
            {item.text}
          </TextIcon>
        ))}
      </div>
    </section>
  )
}
