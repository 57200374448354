import React from 'react'
import * as styles from '@css/text_icon.module.css'
import { GradientPolygon } from '@icons'
import classNames from 'classnames'

export const TextIcon = ({ children, title }) => (
  <div className={classNames('flex-item', styles.wrapper)}>
    <div className={styles.title}>
      <GradientPolygon className={styles.icon} />
      <h3>{title}</h3>
    </div>
    <p className={styles.text}>{children}</p>
  </div>
)
